export enum AccountType {
  INVESTOR = 'INVESTOR',
  BANKER = 'BANKER',
  PRIMARY_PORTAL_AGENT = 'PRIMARY_PORTAL_AGENT',
  SELL_SIDE_SALES = 'SELL_SIDE_SALES',
}

export enum UserDistributionStatus {
  REG_S = 'REG_S',
  QIBS = 'QIBS',
}

export enum AccountRole {
  DEFAULT_USER = 'DEFAULT_USER',
  TAAS = 'TAAS',
  ADMIN = 'ADMIN',
}
